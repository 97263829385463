<!--
  PACKAGE_NAME : src/pages/ui/components/esp-dx-data-grid-demo.vue
  FILE_NAME : esp-dx-data-grid-demo
  AUTHOR : hmlee
  DATE : 2024-11-4
  DESCRIPTION : 그리드 데모 페이지
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {
    EspDxDataGrid,
  },
  props: {},
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'ivrServerRefName',
        dataSourceDefaultSortColumn: '+svrOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
        apiActionNm: {
          select: 'CC_IVR_SVR_LIST',
          merge: 'CC_IVR_SVR_MERGE',
          delete: 'CC_IVR_SVR_DELETE',
        },
        showActionButtons: { // 그리드 버튼 노출 설정값
          //select: false, // 조회 / false가 기본
          //update: true, // 수정 / true가 기본
          //delete: true, // 삭제 / true가 기본
          //copy: true, // 복사 / false가 기본
          //excel: true, // 엑셀 다운로드 / false가 기본
          //csv: true, // CSV 다운로드 / excel: true 시 사용 가능 / false가 기본
          customButtons: [ // 커스텀 버튼 / []가 기본
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '커스텀',
                elementAttr: { class: 'btn_XS white light_filled' },
                width: 60,
                height: 30,
                onClick: () => {
                  console.log('커스텀 버튼 클릭');
                },
              },
              location: 'before',
            },
          ],
        },
        showContextMenuItems: { // 컨텍스트 메뉴 노출 설정값 / false가 기본
          //insert: true, // 행 복사
          //copy: true, // 행 복사
          //rowClipboard: true, // 행 클립보드
          //cellClipboard: true, // 셀 클립보드
          //excel: true, // 엑셀 다운로드
        },
        //columnChooser: {}, //항목 선택 / false가 기본
        //export: {}, //엑셀 다운로드 설정 / showActionButtons.excel: true 일때만 동작
        filterRow: { // 행 검색 필터 / true가 기본
          //visible: false,
        },
        //grouping: {}, //그룹핑 설정 / false가 기본
        //groupPanel: {}, //그룹핑 패널 / false가 기본
        //headerFilter: {}, // 헤더 필터 / false가 기본
        //loadPanel: {}, //로딩 패널 / false가 기본
        //pager: {}, // 페이저 설정 / true가 기본
        //paging: {}, // 페이징 설정 / true가 기본
        //remoteOperations: {}, // 서버사이드 설정 / false가 기본
        //dragging: {}, //드래깅 설정 / false가 기본
        //scrolling: {}, //스크롤 설정 / paging 설정시 사용 불가
        //searchPanel: {}, //검색 패널 / false가 기본
        //selecting: {}, //선택 설정 / true가 기본
        //summary: {}, //합계 설정 / false가 기본
        columns: [
          {
            caption: 'ID',
            dataField: 'id',
            visible: false,
          },
          {
            caption: '사이트명*',
            i18n: 'COMMON.WORD.SITE_NM',
            dataField: 'siteId',
            lookup: {
              dataSource: this.$store.getters.getSiteList,
              displayExpr: 'siteNm',
              valueExpr: 'id',
            },
            requiredRule: {},
          },
          {
            caption: '센터명*',
            i18n: 'COMMON.WORD.TENANT_NM',
            dataField: 'tenantId',
            lookup: {
              dataSource: this.$store.getters.getTenantList,
              displayExpr: 'tenantNm',
              valueExpr: 'id',
            },
            requiredRule: {},
          },
          {
            caption: 'IVR명*',
            i18n: 'CC.WORD.IVR_NAME'+'*',
            dataField: 'svrNm',
            requiredRule: {},
          },
          {
            caption: 'IVR No *',
            dataField: 'sysNo',
            requiredRule: {},
          },
          {
            caption: 'IP *',
            dataField: 'ip',
            requiredRule: {},
          },
          {
            caption: 'svrUser',
            dataField: 'svrUser',
          },
          {
            caption: 'svrPasswd',
            dataField: 'svrPasswd',
          },
          {
            caption: 'svrMethod',
            dataField: 'svrMethod',
            allowSearch: false,
            allowFiltering: false,
          },
          {
            caption: 'Port',
            dataField: 'svrPort',
            allowSearch: false, //searchPanel 설정시 사용 가능 / false로 설정시 allowFiltering도 false로 설정해야 됨
            //allowFiltering: false,
          },
          {
            caption: '순서',
            i18n: 'COMPONENTS.ORDER',
            dataField: 'svrOrd',
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    onSelectedChanged(e){
      console.log('onSelectedChanged >>>', e);
    }
  },
  created() {

  },
  mounted() {},
};
</script>
